import admin from './admin'
import campaigns from './campaigns'
import clients from './clients'
import contacts from './contacts'
import deals from './deals'
import mediaAssets from './media-assets'
import recommendation from './recommendation'
import requestForProposals from './request-for-proposals'
import tasks from './tasks'
import userActivities from './userActivities'

export default {
  admin,
  campaigns,
  clients,
  contacts,
  deals,
  mediaAssets,
  recommendation,
  requestForProposals,
  tasks,
  userActivities
}
