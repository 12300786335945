export default [
  {
    path: '/workspace',
    alias: '',
    name: 'Workspace',
    component: () => import('@/views/workspace/Index.vue'),
    redirect: {
      name: 'WorkspaceHomepage'
    },
    children: [
      {
        path: '',
        name: 'WorkspaceHomepage',
        component: () => import('@/views/workspace/homepage/Index.vue'),
        meta: {
          hideHeader: false,
          hideNav: false,
          navItem: 'My Workspace',
          contentExtraClass: 'workspace',
          headerText: 'My Workspace',
          subNav: 'workspace'
        }
      },
      {
        path: 'activity',
        name: 'WorkspaceDashboard',
        component: () => import('@/views/workspace/dashboard/Index.vue'),
        meta: {
          hideHeader: false,
          hideNav: false,
          navItem: 'My Workspace',
          contentExtraClass: 'workspace',
          headerText: 'My Workspace',
          subNav: 'workspace'
        }
      },
      {
        path: 'clients',
        name: 'WorkspaceClientList',
        component: () => import('@/views/workspace/clients/Index.vue'),
        children: [
          {
            path: 'all-clients',
            name: 'WorkspaceAllClients',
            component: () => import('@/views/workspace/clients/List.vue'),
            meta: {
              name: 'WorkspaceClients',
              navItem: 'My Workspace',
              permRequired: true
            }
          },
          {
            path: 'my-clients',
            name: 'WorkspaceMyClients',
            alias: '',
            component: () => import('@/views/workspace/clients/List.vue'),
            meta: {
              name: 'WorkspaceClients',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'created-clients',
            name: 'WorkspaceCreatedClients',
            component: () => import('@/views/workspace/clients/List.vue'),
            meta: {
              name: 'WorkspaceClients',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'owned-clients',
            name: 'WorkspaceOwnedClients',
            component: () => import('@/views/workspace/clients/List.vue'),
            meta: {
              name: 'WorkspaceClients',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'collaborated-clients',
            name: 'WorkspaceCollaboratedClients',
            component: () => import('@/views/workspace/clients/List.vue'),
            meta: {
              name: 'WorkspaceClients',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'hidden-clients',
            name: 'WorkspaceHiddenClients',
            component: () => import('@/views/workspace/clients/List.vue'),
            meta: {
              name: 'WorkspaceClients',
              navItem: 'My Workspace'
            }
          }
        ],
        meta: {
          hideHeader: false,
          hideNav: false,
          navItem: 'Workspace',
          contentExtraClass: 'workspace',
          headerText: 'My Workspace',
          subNav: 'workspace'
        }
      },
      {
        path: 'campaigns',
        name: 'WorkspaceCampaignList',
        component: () => import('@/views/workspace/campaigns/Index.vue'),
        children: [
          {
            path: 'all-campaigns',
            alias: '/campaigns/all-campaigns',
            name: 'WorkspaceAllCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'my-campaigns',
            alias: '/campaigns/my-campaigns',
            name: 'WorkspaceMyCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'created-campaigns',
            alias: '/campaigns/created-campaigns',
            name: 'WorkspaceCreatedCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'owned-campaigns',
            alias: '/campaigns/owned-campaigns',
            name: 'WorkspaceOwnedCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'collaborated-campaigns',
            alias: '/campaigns/collaborated-campaigns',
            name: 'WorkspaceCollaboratedCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'salesforce-opportunities/:externalSystemId',
            alias: '/campaigns/salesforce-opportunities',
            name: 'WorkspaceSalesforceCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'adpoint-opportunities/:externalSystemId',
            alias: '/campaigns/adpoint-opportunities',
            name: 'WorkspaceAdpointCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'naviga-opportunities/:externalSystemId',
            alias: '/campaigns/naviga-opportunities',
            name: 'WorkspaceNavigaCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'sell-opportunities/:externalSystemId',
            alias: '/campaigns/sell-opportunities',
            name: 'WorkspaceSellCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'titan-orders/:externalSystemId',
            alias: '/campaigns/titan-orders',
            name: 'WorkspaceTitanCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'microsoft-opportunities/:externalSystemId',
            alias: '/campaigns/microsoft-opportunities',
            name: 'WorkspaceMicrosoftCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          },
          {
            path: 'hubspot-opportunities/:externalSystemId',
            alias: '/campaigns/hubspot-opportunities',
            name: 'WorkspaceHubspotCampaigns',
            component: () => import('@/views/workspace/campaigns/List.vue'),
            meta: {
              name: 'WorkspaceCampaigns',
              navItem: 'My Workspace'
            }
          }
        ],
        meta: {
          hideHeader: false,
          hideNav: false,
          contentExtraClass: 'workspace',
          navItem: 'Workspace',
          headerText: 'My Workspace',
          subNav: 'workspace'
        }
      },
      {
        path: 'contacts',
        name: 'WorkspaceContactList',
        component: () => import('@/views/workspace/contacts/Index.vue'),
        children: [
          {
            path: 'all-contacts',
            name: 'WorkspaceAllContacts',
            component: () => import('@/views/workspace/contacts/List.vue'),
            meta: {
              name: 'WorkspaceContacts',
              navItem: 'My Workspace',
              permRequired: true
            }
          },
          {
            path: 'my-contacts',
            name: 'WorkspaceMyContacts',
            component: () => import('@/views/workspace/contacts/List.vue'),
            meta: {
              name: 'WorkspaceContacts',
              navItem: 'My Workspace',
              permRequired: true
            }
          }
        ],
        meta: {
          hideHeader: false,
          hideNav: false,
          navItem: 'Workspace',
          contentExtraClass: 'workspace',
          headerText: 'My Workspace',
          subNav: 'workspace'
        }
      },
      {
        path: 'request-for-proposals',
        name: 'WorkspaceRequestForProposalList',
        component: () => import('@/views/workspace/requestForProposal/Index.vue'),
        children: [
          {
            path: 'all-request-for-proposals',
            name: 'WorkspaceAllRequestForProposals',
            component: () => import('@/views/workspace/requestForProposal/List.vue'),
            meta: {
              name: 'WorkspaceRequestForProposals',
              navItem: 'My Workspace',
              permRequired: true
            }
          },
          {
            path: 'my-request-for-proposals',
            name: 'WorkspaceMyRequestForProposals',
            component: () => import('@/views/workspace/requestForProposal/List.vue'),
            meta: {
              name: 'WorkspaceRequestForProposals',
              navItem: 'My Workspace',
              permRequired: true
            }
          }
        ],
        meta: {
          hideHeader: false,
          hideNav: false,
          navItem: 'Workspace',
          contentExtraClass: 'workspace',
          headerText: 'My Workspace',
          subNav: 'workspace'
        }
      },
      {
        path: 'agencies',
        name: 'WorkspaceAgencyList',
        component: () => import('@/views/workspace/agencies/Index.vue'),
        children: [
          {
            path: 'all-agencies',
            alias: '/agencies/all-agencies',
            name: 'WorkspaceAllAgencies',
            component: () => import('@/views/workspace/agencies/List.vue'),
            meta: {
              name: 'WorkspaceAgencies',
              navItem: 'My Workspace'
            }
          }
        ],
        meta: {
          hideHeader: false,
          hideNav: false,
          contentExtraClass: 'workspace',
          navItem: 'Workspace',
          headerText: 'My Workspace',
          subNav: 'workspace'
        }
      },

      {
        path: 'quick-order',
        name: 'CampaignQuickOrder',
        component: () => import('@/views/workspace/QuickOrder.vue'),
        meta: {
          navItem: 'My Workspace',
          headerText: 'Quick Order',
          headerBackRoute: 'WorkspaceCampaignList',
          contentExtraClass: 'no-content-padding',
          mainContentClass: 'main-content-full-width-no-padding',
        }
      },
      {
        path: 'quick-order/:id/edit',
        name: 'CampaignEditQuickOrder',
        component: () => import('@/views/workspace/QuickOrder.vue'),
        meta: {
          navItem: 'My Workspace',
          headerText: 'Quick Order',
          headerBackRoute: 'CampaignSummary',
          contentExtraClass: 'no-content-padding',
          mainContentClass: 'main-content-full-width-no-padding'
        }
      }
    ]
  }
]
